import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debug from 'debug';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import styled, { css } from 'styled-components';
import { parse } from 'subtitle';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import {
  updateReadingAssignmentPage,
  resetAssignmentPage,
  nextAssignmentPage,
  closeErrorMessage,
  closeConnectionErrorMessage,
  closeReadyMessage,
  allowNavigation,
  prohibitNavigation,
} from './ReadingAssignmentActions';
import {
  updateBackgroundColor,
} from '../app/AppActions';
import Exercise from './Exercise';
import PaginationControls from './PaginationControls';
import ExerciseControls from './ExerciseControls';
import ReadingControls from './ReadingControls';
import RecordingControls from './RecordingControls';
import readingIcon from './icons/readingIcon.png';
import recordingIcon from './icons/recordingIcon.png';
import readingIconActive from './icons/readingIcon-active.png';
import recordingIconActive from './icons/recordingIcon-active.png';
import exerciseFinished from './icons/exercise-finished.png';
import errorMark from './icons/message-error-mark.png';
import microphone from './icons/microphone.png';
import iconContinue from './icons/message-continue.png';
import iconError from './icons/message-error.png';
import AreYouSure from '../components/AreYouSure';
import ModalMessage from '../components/ModalMessage';
import LoadingIndicator from '../components/LoadingIndicator';
import { resetBox, verticalCenter } from '../shared/styles';
import { audioPlayer } from '../shared/itslanguage';

const logger = debug('its-tuto:logs');

/*
 * IMPORTANT NOTE
 *
 * Unfortunately, react-tabs does not allow to be styled through styled-components (yet).
 * see: https://github.com/reactjs/react-tabs/issues/148
 *
 * The way this component is styled is not necessarily the preferred way, but it works. As
 * soon as react-tabs supports styled-components (thus, overriding its styling!) we can
 * make this code way more pretty and awesome.
 *
 * Side note: re-inventing a working tab component was not the goal.
 */

const Page = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .react-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 562px;
    overflow: hidden;
  }

  .react-tabs__tab-list {
    display: flex;
    flex: 1;
  }

  .react-tabs ul {
    ${resetBox()}
    border-bottom: 2px solid #d5d5d5;
    display: flex;
    flex: 0 0 3.556rem;
    flex-direction: row;
    list-style: none;

    > li {
      ${verticalCenter({ textAlign: 'center' })}
      flex: 1;
      font-size: 0.889rem;

      &:first-child {
        border-right: 2px solid #d5d5d5;
      }
    }
  }

  .react-tabs__tab--selected {
    font-weight: bold;
  }

  .react-tabs__tab:hover:not(.react-tabs__tab--selected) {
    background-color: #f7f7f7;
    cursor: pointer;
  }

  .react-tabs__tab.react-tabs__tab--disabled {
    display: none;
  }
`;

const PageControls = styled.footer`
  display: flex;
  flex: 0 0 4.778rem;
  flex-direction: row;
  justify-content: center;

  > div {
    display: flex;
    flex: 1;
    height: 100%;
    padding: 1.3rem 1.556rem;
  }
`;

const TabLabel = styled.span`
  ${verticalCenter({ textAlign: 'center' })}
  flex: 1;
  position: relative;

  ${(props) => props.active && css`
    border-bottom: 2px solid ${props.color};
    color: ${props.color};
    margin-bottom: -2px;
  `}

  &::before {
    background-image: url(${(props) => (props.active ? props.iconActive : props.icon)});
    background-size: 2.444rem 2.444rem;
    background-repeat: no-repeat;
    content: ' ';
    height: 2.444rem;
    left: 1.556rem;
    position: absolute;
    top: 0.556rem;
    width: 2.444rem;
  }
`;

/**
 * Reading-assignment page component. After selecting a theme, week and
 * assignment the 'ReadingAssignment' will be shown to the user.
 *
 * This effectively means that a ReadingAssignment is just another
 * representation of a Category. Only this category has one (or more)
 * SpeechChallenges which will be used to show data.
 *
 * The page uses state because of the tabs and styled components.
 * At this moment it was the only way to get the correct styling.
 */
export class LegacyReadingAssignment extends Component {
  static propTypes = {
    resetAssignmentPage: PropTypes.func.isRequired,
    params: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
    updateBackgroundColor: PropTypes.func.isRequired,
    updateReadingAssignmentPage: PropTypes.func.isRequired,
    router: PropTypes.shape().isRequired,
    route: PropTypes.shape().isRequired,
    activePage: PropTypes.shape().isRequired,
    showErrorMessage: PropTypes.bool.isRequired,
    closeErrorMessage: PropTypes.func.isRequired,
    showConnectionErrorMessage: PropTypes.bool.isRequired,
    closeConnectionErrorMessage: PropTypes.func.isRequired,
    showReadyMessage: PropTypes.bool.isRequired,
    closeReadyMessage: PropTypes.func.isRequired,
    allowNavigation: PropTypes.func.isRequired,
    recordingState: PropTypes.string,
  };

  prepareBindings() {
    this.routerWillLeave = this.routerWillLeave.bind(this);
    this.closeErrorMessage = this.closeErrorMessage.bind(this);
    this.closeConnectionErrorMessage = this.closeConnectionErrorMessage.bind(this);
    this.closeReadyMessage = this.closeReadyMessage.bind(this);
    this.leaveAssignment = this.leaveAssignment.bind(this);
    this.notLeavingAssignment = this.notLeavingAssignment.bind(this);
    this.startRecording = this.startRecording.bind(this);
    this.allowNavigation = this.allowNavigation.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleMistake = this.handleMistake.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      activeTab: 0,
      speechChallenges: null,
      previousRecordingUrl: null,
      tryLeaving: false,
      mayLeave: false,
      nextLocation: null,
      navigationDisabled: false,
      words: [],
      firstWordPerSentence: [],
      numberOfWords: 0,
      numberOfMistakes: 0,
      fetchingSubs: false,
      microphoneError: false,
      showReadyMessage: false,
    };

    this.props.resetAssignmentPage();

    // Set up some bindigs so scoping is in order;
    this.prepareBindings();

    // Prepare the route leave hook
    this.props.router.setRouteLeaveHook(
      this.props.route,
      this.routerWillLeave,
    );
  }

  componentDidMount() {
    const {
      params: { assignmentId },
      location: { query: { pageId = 1 } },
    } = this.props;

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_TUTO_API_URL}/category/${assignmentId}`,
    }).then((response) => {
      const category = response.data;
      this.setState({
        loading: false,
        speechChallenges: [...category.prompts.sort((a, b) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        })],
      });

      axios({
        method: 'get',
        url: `${process.env.REACT_APP_TUTO_API_URL}/user`,
      }).then((responseUser) => {
        const user = responseUser.data;
        const feedbackModelEnabled = user.feedback_model;
        const timescaleEnabled = false;
        const readingModeEnabled = user.reading_mode;

        this.props.updateBackgroundColor(category.color);
        this.props.updateReadingAssignmentPage({
          feedbackModelEnabled,
          timescaleEnabled,
          readingModeEnabled,
          pageCount: category.prompts.length,
          activePage: {
            pageId: Number(pageId),
            speechChallenge: category.prompts[pageId - 1],
            speechChallengeRecording: {},
          },
        });
      });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      activePage: {
        pageId: oldPageId,
        speechChallengeRecording: oldRecording,
      },
    } = prevProps;

    const {
      activePage: {
        pageId: newPageId,
        speechChallengeRecording: newRecording,
      },
      readingModeEnabled: currReadingModeEnabled,
    } = this.props;

    // Page navigation
    if (oldPageId !== newPageId
      && this.state.speechChallenges !== null
      && this.state.speechChallenges[newPageId - 1]) {
      // Go into loading space;
      this.setState({ loading: true });

      this.getRecordings(this.state.speechChallenges[newPageId - 1].id).then((recording) => {
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_TUTO_API_URL}/user`,
        }).then((responseUser) => {
          const user = responseUser.data;
          const feedbackModelEnabled = user.feedback_model;
          const timescaleEnabled = false;
          const readingModeEnabled = user.reading_mode;

          logger(`Feedbackmodel mode is on: ${feedbackModelEnabled}`);
          logger(`Reading mode is on: ${readingModeEnabled}`);

          this.props.updateReadingAssignmentPage({
            feedbackModelEnabled,
            timescaleEnabled,
            readingModeEnabled,
            activePage: {
              speechChallenge: this.state.speechChallenges[newPageId - 1],
              speechChallengeRecording: recording,
            },
          });

          this.setState({ loading: false });
        });
      });

      // Load SRT from state and current active page!
      if (this.state.speechChallenges[newPageId - 1].srt) {
        this.parseSrt(this.state.speechChallenges[newPageId - 1].srt);
      }
    }

    // Update the tab based on the current mode;
    // Go to recording tab if reading is disabled;
    if (!currReadingModeEnabled && this.state.activeTab === 0) {
      this.setState({ activeTab: 1 });
    }

    // Process recording added and more general allow navigation point
    if ((!oldRecording || (oldRecording && !oldRecording.id)) && newRecording && newRecording.id) {
      this.allowNavigation();
    }
  }

  componentWillUnmount() {
    if (this.props.showErrorMessage) {
      this.closeErrorMessage();
    }
  }

  getRecordings(speechChallengeId = null) {
    if (speechChallengeId) {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_TUTO_API_URL}/prompt/${speechChallengeId}/recordings`,
      }).then((response) => response.data[response.data.length - 1]);
    }
    return Promise.reject(new Error('No speechChallenge provided when trying to get recordings..'));
  }

  routerWillLeave(nextLocation) {
    this.setState({
      tryLeaving: true,
      nextLocation,
    });

    return this.state.mayLeave;
  }

  parseSrt(srt) {
    this.setState({ fetchingSubs: true });
    // Use Array.reduce to construct an array of words, and accumulate
    // a total of sentences based on the <EoS> character. I have not
    // used the [lf] here, because they were added for styling, not for
    // identifying sentences.
    const { words, firstWordPerSentence } = parse(srt)
      .reduce((acc, item, index, allWords) => {
        const wps = [...acc.firstWordPerSentence];
        // Sentence index is the position of a word in the sentence. Note that
        // counting starts at 1, similar to the backend output!
        const sentenceIndex = (index - wps[wps.length - 1] + 1);
        // Counting starts at 1, similar to the backend output!
        const sentence = wps.length;
        const targetWordRegExp = RegExp(/\[(tw.*?)]/);
        const targetWord = targetWordRegExp.test(item.text);
        let targetWordIndex = null;

        // Keep track of words per "real" sentence.
        // Determine this based on an existing "<EoS>" token.
        if (item.text.includes('[lf]') && index < allWords.length - 1) {
          // We add two, index is the index of the current word but we need
          // to set the textIndex of the NEXT word, which started counting at 1.
          wps.push(index + 2);
        }

        if (targetWord) {
          // The target word looks like "[tw:1]". Our regex
          // will give us an array with two items: [ "[tw:1]", "tw:1" ]
          // We need to get the "1" and store that on targetWordIndex;
          targetWordIndex = targetWordRegExp
            .exec(item.text)[1] // Get the one match that matters 😎
            .split(':')[1] * 1; // Return the value only (as Number);
        }

        // Hidden word to display unk
        acc.words.push({
          textIndex: index + 0.5,
          sentenceIndex,
          sentence,
          startIndicator: false,
          highlight: false,
          error: false,
          start: 0.1,
          end: 0.1,
          feedback: false,
          type: 'unk',
          // Thin space unicode character, so an underline can be shown
          displayText: ' ',
          text: '',
        });

        return {
          // Gather the previous words and add the new ones.
          words: [
            ...acc.words,
            {
              textIndex: index + 1, // Counting starts at 1!
              sentenceIndex,
              sentence,
              startIndicator: false,
              highlight: false,
              error: false,
              feedback: false,
              // Display text will be rendered to the browser. So strip data we do not need.
              displayText: item.text.replace(/\[(.*?)]|<EoS>/g, '').trim(),
              targetWord,
              targetWordIndex,
              ...item,
            },
          ],
          firstWordPerSentence: [...wps],
        };
      }, { words: [], firstWordPerSentence: [1] });

    // Filter out unk words
    const realWords = words.filter((word) => word.textIndex % 1 === 0);

    this.setState({
      words,
      firstWordPerSentence,
      numberOfWords: realWords.length,
      fetchingSubs: false,
    });
  }

  leaveAssignment() {
    this.setState({
      tryLeaving: false,
      mayLeave: true,
    }, () => {
      browserHistory.push(this.state.nextLocation);
    });
  }

  notLeavingAssignment() {
    this.setState({
      tryLeaving: false,
      mayLeave: false,
    });
  }

  startRecording() {
    this.setState({
      navigationDisabled: false,
    });
  }

  allowNavigation() {
    this.setState({
      navigationDisabled: false,
    });
    this.props.allowNavigation();
  }

  stopAudio() {
    if (!audioPlayer.paused) {
      audioPlayer.pause();
    }
  }

  nextPage() {
    if (this.props.activePage.pageId < this.state.speechChallenges.length) {
      this.props.nextAssignmentPage(this.props.activePage.pageId);
    } else {
      this.setState(() => ({
        showReadyMessage: true,
      }));
    }
  }

  handleMistake() {
    this.setState((prevState) => ({
      numberOfMistakes: prevState.numberOfMistakes + 1,
    }));
  }

  handleSelect(index) {
    this.setState({
      activeTab: index,
    });
  }

  closeErrorMessage() {
    this.props.closeErrorMessage();
    this.setState({
      activeTab: 1,
    });
  }

  closeConnectionErrorMessage() {
    this.props.closeConnectionErrorMessage();
  }

  closeReadyMessage() {
    // The ready message is only available if all recordings have been done. So
    // it's safe to asume mayLeave can be true.
    this.setState({
      mayLeave: true,
    }, () => {
      const { params: { themeId, weekId } } = this.props;
      this.props.closeReadyMessage(themeId, weekId);
    });
  }

  renderConnectionError() {
    const { t } = this.props;

    return (
      <Page>
        <ModalMessage
          buttonText={t('reading.error.try_again')}
          buttonIcon={iconError}
          imageSrc={microphone}
          lines={[t('reading.error.try_again_description_1'), t('reading.error.try_again_decsription_2')]}
          onClose={this.closeConnectionErrorMessage}
        />
      </Page>
    );
  }

  renderConstraintsError() {
    const { microphoneError } = this.state;
    const { t } = this.props;
    const lines = [
      t('reading.error.constraint_description_1'),
      t('reading.error.constraint_description_2'),
      t('reading.error.constraint_description_3'),
      t('reading.error.constraint_description_4'),
      `$(t('reading.error.constraint_description_5')) ${microphoneError}`,
    ];

    return (
      <Page>
        <ModalMessage
          lines={lines}
          imageSrc={errorMark}
        />
      </Page>
    );
  }

  renderReadingDone() {
    const { t } = this.props;

    return (
      <Page>
        <ModalMessage
          buttonText={t('reading.continue')}
          buttonIcon={iconContinue}
          imageSrc={exerciseFinished}
          lines={[t('reading.done_description_1'), `${this.state.numberOfMistakes} ${t('reading.done_description_2')}`]}
          onClose={this.closeReadyMessage}
        />
      </Page>
    );
  }

  renderReadingNotDone() {
    const { t } = this.props;
    return (
      <Page>
        <ModalMessage
          buttonText={t('reading.error.not_done_button')}
          buttonIcon={iconError}
          imageSrc={microphone}
          lines={[t('reading.error.not_done_description_1'), t('reading.error.not_done_description_2')]}
          onClose={this.closeErrorMessage}
        />
      </Page>
    );
  }

  renderLoading() {
    return (
      <Page>
        <LoadingIndicator />
      </Page>
    );
  }

  render() {
    if (this.props.showConnectionErrorMessage) {
      return this.renderConnectionError();
    }

    // Show an error if no speech challenge was set
    if (this.state.speechChallenges && this.state.speechChallenges.length === 0) {
      return this.renderConnectionError();
    }

    if (this.state.loading) {
      return this.renderLoading();
    }

    if (this.state.microphoneError) {
      return this.renderConstraintsError();
    }

    if (this.state.showReadyMessage) {
      return this.renderReadingDone();
    }

    if (this.props.showErrorMessage) {
      return this.renderReadingNotDone();
    }

    if (this.props.activePage.speechChallenge) {
      const {
        readingModeEnabled,
        feedbackModelEnabled,
        activePage: {
          speechChallenge,
          feedbackOnWords,
        },
        recordingState,
        t,
      } = this.props;

      const {
        tryLeaving,
        activeTab,
        words,
        firstWordPerSentence,
        numberOfWords,
        navigationDisabled,
      } = this.state;

      return (
        <Page>
          {
            tryLeaving
              && (
              <AreYouSure
                onThumbUpClick={this.leaveAssignment}
                onThumbDownClick={this.notLeavingAssignment}
                title={t('confirm.title')}
                yes={t('confirm.yes')}
                no={t('confirm.no')}
              />
              )
          }
          <Tabs onSelect={this.handleSelect} selectedIndex={activeTab}>
            <TabList>
              {/* Tab with index: 0 */}
              <Tab disabled={!readingModeEnabled}>
                <TabLabel
                  active={activeTab === 0}
                  color="#92b44a"
                  icon={readingIcon}
                  iconActive={readingIconActive}
                >
                  {t('reading.listen')}
                </TabLabel>
              </Tab>

              {/* Tab with index: 1 */}
              <Tab>
                <TabLabel
                  active={activeTab === 1}
                  color="#2c8de8"
                  icon={recordingIcon}
                  iconActive={recordingIconActive}
                >
                  {t('reading.read')}
                </TabLabel>
              </Tab>
            </TabList>

            <TabPanel>
              <Exercise
                type="reading"
                words={words}
                firstWordPerSentence={firstWordPerSentence}
                numberOfWords={numberOfWords}
                speechChallenge={speechChallenge}
              />
            </TabPanel>

            <TabPanel>
              <Exercise
                type="recording"
                showStartIndicator={(recordingState === 'prepare' || recordingState === 'started')}
                feedbackOnWords={feedbackOnWords}
                words={words}
                firstWordPerSentence={firstWordPerSentence}
                numberOfWords={numberOfWords}
                speechChallenge={speechChallenge}
                feedbackModelEnabled={feedbackModelEnabled}
                totalMistakes={this.state.numberOfMistakes}
              />
            </TabPanel>
          </Tabs>
          <PageControls>
            <div>
              {/* First active tab means that the reading controls need to be shown */}
              {activeTab === 0
                && (
                <ReadingControls
                  audioUrl={`${process.env.REACT_APP_TUTO_API_URL}/prompt/${speechChallenge.id}/reference`}
                />
                )}

              {/* Second active tab means student must be allowed recording audio */}
              {activeTab === 1
                && (
                <RecordingControls
                  feedbackAudioUrl={`${process.env.REACT_APP_TUTO_API_URL}/prompt/${speechChallenge.id}/reference`}
                  words={words}
                  numberOfWords={numberOfWords}
                  handleMistake={this.handleMistake}
                  nextPage={this.nextPage}
                />
                )}
            </div>
            <PaginationControls disabled={navigationDisabled} location={this.props.location} />
            <ExerciseControls disabled={navigationDisabled} />
          </PageControls>
        </Page>
      );
    }
    return null;
  }
}

// container part below
const mapStateToProps = (state) => ({ ...state.ReadingAssignment });

const mapDispatchToProps = {
  updateReadingAssignmentPage,
  closeErrorMessage,
  closeConnectionErrorMessage,
  closeReadyMessage,
  resetAssignmentPage,
  nextAssignmentPage,
  allowNavigation,
  prohibitNavigation,
  updateBackgroundColor,
};

const ReadingAssignment = withTranslation()(LegacyReadingAssignment);

export default connect(mapStateToProps, mapDispatchToProps)(ReadingAssignment);
