import { browserHistory } from 'react-router';

/*
 * Action types
 */

export const UPDATE_READING_ASSIGNMENT_PAGE = 'UPDATE_READING_ASSIGNMENT_PAGE';
export const PREV_ASSIGNMENT_PAGE = 'PREV_ASSIGNMENT_PAGE';
export const NEXT_ASSIGNMENT_PAGE = 'NEXT_ASSIGNMENT_PAGE';
export const RESET_ASSIGNMENT_PAGE = 'RESET_ASSIGNMENT_PAGE';
export const UPDATE_PAGE_COUNT = 'UPDATE_PAGE_COUNT';
export const UPDATE_SPEECHCHALLENGE = 'UPDATE_SPEECHCHALLENGE';
export const UPDATE_SPEECHCHALLENGE_RECORDING = 'UPDATE_SPEECHCHALLENGE_RECORDING';
export const UPDATE_FEEDBACK_ON_WORDS = 'UPDATE_FEEDBACK_ON_WORDS';
export const RESET_FEEDBACK_ON_WORDS = 'RESET_FEEDBACK_ON_WORDS';
export const ALLOW_NAVIGATION = 'ALLOW_NAVIGATION';
export const PROHIBIT_NAVIGATION = 'PROHIBIT_NAVIGATION';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_CONNECTION_ERROR_MESSAGE = 'SHOW_CONNECTION_ERROR_MESSAGE';
export const CLOSE_CONNECTION_ERROR_MESSAGE = 'CLOSE_CONNECTION_ERROR_MESSAGE';
export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE';
export const SHOW_READY_MESSAGE = 'SHOW_READY_MESSAGE';
export const CLOSE_READY_MESSAGE = 'CLOSE_READY_MESSAGE';
export const UPDATE_RECORDING_STATE = 'UPDATE_RECORDING_STATE';
export const ENABLE_FEEDBACK_MODEL = 'ENABLE_FEEDBACK_MODEL';
export const DISABLE_FEEDBACK_MODEL = 'DISABLE_FEEDBACK_MODEL';
export const ENABLE_TIMESCALE = 'ENABLE_TIMESCALE';
export const DISABLE_TIMESCALE = 'DISABLE_TIMESCALE';

/*
 * Other costants
 */
export const readingStates = {
  pageCount: 0,
  showErrorMessage: false,
  showReadyMessage: false,
  showConnectionErrorMessage: false,
  recordingState: null,
  feedbackModelEnabled: true,
  timescaleEnabled: false,
  readingModeEnabled: true,
  activePage: {
    pageId: 0,
    allowNavigation: false,
    speechChallenge: null,
    speechChallengeRecording: null,
    feedbackOnWords: [],
    startAtWord: 1,
  },
};

/*
 * Action creators
 */

export function updateReadingAssignmentPage(readingState) {
  return {
    type: UPDATE_READING_ASSIGNMENT_PAGE,
    ...readingState,
  };
}

export function prevAssignmentPage(currentPageId, pageStep = 1) {
  return {
    type: PREV_ASSIGNMENT_PAGE,
    feedbackOnWords: [],
    pageId: currentPageId - pageStep,
  };
}

export function nextAssignmentPage(currentPageId, pageStep = 1) {
  return {
    type: NEXT_ASSIGNMENT_PAGE,
    feedbackOnWords: [],
    pageId: currentPageId + pageStep,
  };
}

export function resetAssignmentPage() {
  return {
    type: RESET_ASSIGNMENT_PAGE,
    feedbackOnWords: [],
    pageId: 0,
  };
}

export function updatePageCount(newCount) {
  return {
    type: UPDATE_PAGE_COUNT,
    pageCount: newCount,
  };
}

export function allowNavigation() {
  return {
    type: ALLOW_NAVIGATION,
    allowNavigation: true,
  };
}

export function prohibitNavigation() {
  return {
    type: PROHIBIT_NAVIGATION,
    allowNavigation: false,
  };
}

export function updateSpeechChallenge(speechChallenge) {
  return {
    type: UPDATE_SPEECHCHALLENGE,
    speechChallenge,
  };
}

export function updateSpeechChallengeRecording(speechChallengeRecording) {
  return {
    type: UPDATE_SPEECHCHALLENGE_RECORDING,
    speechChallengeRecording,
  };
}

export function updateFeedbackOnWords(feedbackOnWords) {
  return {
    type: UPDATE_FEEDBACK_ON_WORDS,
    feedbackOnWords,
  };
}

export function resetFeedbackOnWords() {
  return {
    type: RESET_FEEDBACK_ON_WORDS,
    feedbackOnWords: [],
  };
}

export function closeErrorMessage() {
  return {
    type: CLOSE_ERROR_MESSAGE,
    showErrorMessage: false,
  };
}

export function showErrorMessage() {
  return {
    type: SHOW_ERROR_MESSAGE,
    showErrorMessage: true,
  };
}

export function showConnectionErrorMessage() {
  return {
    type: SHOW_CONNECTION_ERROR_MESSAGE,
    showConnectionErrorMessage: true,
  };
}

export function closeConnectionErrorMessage() {
  return {
    type: CLOSE_CONNECTION_ERROR_MESSAGE,
    showConnectionErrorMessage: false,
  };
}

export function showReadyMessage() {
  return {
    type: SHOW_READY_MESSAGE,
    showReadyMessage: true,
  };
}

export function closeReadyMessage(themeId, weekId) {
  let url = '/themas';

  if (themeId) {
    url += `/${themeId}`;
  }

  if (themeId && weekId) {
    url += `/weken/${weekId}`;
  }

  browserHistory.push(url);
  return {
    type: CLOSE_READY_MESSAGE,
    showReadyMessage: false,
  };
}

function updateRecordingState(recordingState, wordIndex) {
  const action = {
    type: UPDATE_RECORDING_STATE,
    recordingState,
  };

  if (typeof wordIndex === 'number') {
    action.word = wordIndex;
  }

  return action;
}

export function prepareRecording(word) {
  return updateRecordingState('prepare', word);
}

export function startRecording() {
  return updateRecordingState('started');
}

export function stopRecording() {
  return updateRecordingState('stopped', 1);
}

export function pauseRecording() {
  return updateRecordingState('paused');
}

export function enableFeedbackModel() {
  return {
    type: ENABLE_FEEDBACK_MODEL,
    feedbackModelEnabled: true,
  };
}

export function disableFeedbackModel() {
  return {
    type: DISABLE_FEEDBACK_MODEL,
    feedbackModelEnabled: false,
  };
}

export function enableTimescale() {
  return {
    type: ENABLE_TIMESCALE,
    timescaleEnabled: true,
  };
}

export function disableTimescale() {
  return {
    type: DISABLE_TIMESCALE,
    timescaleEnabled: false,
  };
}
